/**
 *
 * This is the website layout / wrapper
 * Look in gatsby-browser.js for how this wraps the website
 *
 */

import React, { useState, useEffect } from "react"
// import Header from '../modules/Header/Header';
// import Footer from '../modules/Footer/Footer';
import { useStaticQuery, graphql } from "gatsby"

// TODO: move Header component to here.
// ? Currently running into an issue where the slug value that is passed to the header isn't updating when the page changes.
//  use the "StaticQuery" component to dynamically fetch the current page slug and pass it to the header?

const PageLayout = ({ children }) => {
  const { wpPage, wp, wpMenu } = useStaticQuery(
    graphql`
      query {
        wp {
          acfOptionsGeneralSettings {
            themeSettings {
              button1 {
                buttonType
                link {
                  target
                  title
                  url
                }
              }
              button2 {
                buttonType
                link {
                  target
                  title
                  url
                }
              }
              largeBottomText
              fieldGroupName
              textUnderLogo
              customCss
            }
          }
        }
        wpMenu(locations: { eq: GATSBY_FOOTER_MENU }) {
          menuItems {
            nodes {
              label
              url
            }
          }
        }
        wpPage {
          slug
        }
      }
    `
  )

  // const { slug } = wpPage;
  const customCss =
    wp?.acfOptionsGeneralSettings?.themeSettings?.customCss || ""
  const menuItems = wpMenu?.menuItems ? wpMenu.menuItems : false
  const {
    acfOptionsGeneralSettings: {
      themeSettings: {
        button1,
        button2,
        largeBottomText,
        fieldGroupName,
        textUnderLogo,
      },
    },
  } = wp
  // TODO: Pass slug to header to determine if the header bg is transparent or solid.

  useEffect(() => {
    const style = document.createElement("style")
    style.textContent = customCss
    document.head.appendChild(style)

    return () => {
      document.head.removeChild(style)
    }
  }, [])
  return (
    <>
      {/* <Header data={{button1, button2}} slug={slug} /> */}
      {children}
      {/* <Footer data={{largeBottomText, fieldGroupName, textUnderLogo}} menuItems={menuItems} /> */}
    </>
  )
}

export default PageLayout
