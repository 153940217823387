/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from "react"
import "./src/styles/_reboot.scss"
import "./src/styles/_global.scss"

import PageLayout from "./src/layouts/layout"

export const wrapPageElement = ({ element }) => {
  return <PageLayout>{element}</PageLayout>
}

//require('./src/layouts/layout.scss')

