module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://cms.mlc.wa.edu.au/graphql","excludeFieldNames":["allWpGfEntry","allWpGfDraftEntry","allWpGfSubmittedEntry","wpGfEntry","wpGfDraftEntry","wpGfSubmitterEntry","GfEntry","GfSubmittedEntry"],"schema":{"typePrefix":"Wp","perPage":7,"requestConcurrency":4,"previewRequestConcurrency":4,"timeout":400000,"queryDepth":15,"circularQueryLimit":5},"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/opt/build/repo/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7ff488cce9849e20ca6d1af2ccb7dcf8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.mlc.wa.edu.au/"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
